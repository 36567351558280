<template>
  <el-dialog title="数据转移" :visible.sync="openDialog" width="500px" :show-close="true" class="dialog-vertical"
    @close="cancel('CategoryName')">
    <el-form v-loading="loading" size="medium" :model="CategoryData" :rules="rules" ref="CategoryName"
      label-width="95px">
      <el-form-item label="转出分类：" prop="name">
        <el-input type="text" style="width: 80%" v-model="CategoryData.name" disabled></el-input>
      </el-form-item>
      <el-form-item label="选择分类：" prop="merge_category_id">
        <el-select v-model="CategoryData.merge_category_id" :placeholder="selectable?'请选择分类':'当前无可转移分类'"
          style="width: 80%" :disabled="!selectable">
          <el-option v-for="item in selectArr" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('CategoryName')">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  mergeCategory,
  CategoryList,
} from "../../api/tmpl-category/category-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Data: {
      type: Object,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      loading: true,
      selectArr: [],
      rules: {
        merge_category_id: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.getCategoryList();
      }
    },
  },
  methods: {
    // 获取分类选择列表
    getCategoryList() {
      this.loading = true;
      CategoryList({ exclude_id: this.Data.del_category_id })
        .then((res) => {
          this.selectArr = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {};
          data.id = this.CategoryData.del_category_id;
          data.target_id = this.CategoryData.merge_category_id;
          mergeCategory(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.$refs[formName].resetFields();
              this.openDialog = false;
            })
            .catch((err) => {});
          this.openDialog = false;
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    CategoryData: {
      get() {
        return this.Data;
      },
      set(val) {
        this.$emit("update: updateData", val);
      },
    },
    selectable() {
      return this.selectArr.length > 0;
    },
  },
};
</script>

<style>
</style>
