import api from "@/base/utils/request";

// 获取分类列表
export const tmplCategory = data => {
  return api({
    url: "/admin/blessing/tmpl_category/index",
    method: "post",
    data
  });
};

// 获取分类选择列表
export const CategoryList = data => {
  return api({
    url: "/admin/blessing/tmpl_category/all",
    method: "post",
    data
  });
};

// 更新排序
export const updateSort = data => {
  return api({
    url: "/admin/blessing/tmpl_category/updateSort",
    method: "post",
    data
  });
};

// 更新显示
export const updateShow = data => {
  return api({
    url: "/admin/blessing/tmpl_category/updateStatus",
    method: "post",
    data
  });
};

// 添加分类
export const AddCategory = data => {
  return api({
    url: "/admin/blessing/tmpl_category/save",
    method: "post",
    data
  });
};

// 合并分类
export const mergeCategory = data => {
  return api({
    url: "/admin/blessing/tmpl_category/move",
    method: "post",
    data
  });
};

//删除分类
export const Delete = data => {
  return api({
    url: "/admin/blessing/tmpl_category/del",
    method: "post",
    data
  });
};