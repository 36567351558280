<template>
  <div>
    <div class="body">
      <el-button style="margin-bottom: 20px" type="primary" size="small" @click="handleAddCategory">添加分类
      </el-button>
      <el-button style="margin-bottom: 20px" type="danger" size="small" @click="handleDelCategory">删除
      </el-button>
      <div class="list-content">
        <el-table :data="tbody" class="thead-light" stripe style="width: 100%" v-loading="loading"
          @sort-change="sortChange" @selection-change="handleSelectionChange">
          <!-- 勾选-->
          <el-table-column fixed type="selection" width="55"></el-table-column>
          <!-- 操作 -->
          <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button-group>
                <el-button type="text" size="small" @click="editRow(scope.row)">
                  编辑
                </el-button>
                <el-button type="text" size="small" @click="transferRow(scope.row)">
                  转移
                </el-button>
                <el-button type="text" size="small" @click="deleteRow(scope.row)">
                  删除</el-button>
              </el-button-group>
            </template>
          </el-table-column>
          <template v-for="(th, index) in thead">
            <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
              :min-width="th.minWidth || ''" :key="index" :sortable="th.sortable">
              <template slot-scope="scope">
                <!-- 排序 -->
                <PopoverInputEdit v-if="th.prop === 'sort'" v-model="scope.row[th.prop]" input-type="number"
                  :validator="validator" @confirm="changeStatus(scope.row, th.prop)" />
                <!-- 显示 -->
                <!-- <el-checkbox v-else-if="th.prop === 'is_show'" v-model="tbody[scope.$index][th.prop]"
                  :true-label="1" :false-label="0" @change="changeStatus(scope.row, th.prop)"></el-checkbox> -->
                <!-- 普通 -->
                <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
      <AddClassify v-model="openAddDialog" :Data="CategoryData" :updateData.sync="CategoryData"
        :updateList="reflashList" />
      <TransferClassify v-model="openTransferDialog" :Data="TransferData" :categoryList="categoryList"
        :updateData.sync="TransferData" :updateList="reflashList" />
    </div>
  </div>
</template>

<script>
import Pagination from "@/base/components/Default/Pagination";
import AddClassify from "../../components/Classify/AddClassify";
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import {
  tmplCategory,
  CategoryList,
  updateSort,
  // updateShow,
  Delete,
} from "../../api/tmpl-category/category-list";
import TransferClassify from "../../components/Classify/TransferClassify.vue";
export default {
  data() {
    return {
      tabArr: [],
      loading: false, //加载
      thead: [
        { label: "ID", prop: "id", minWidth: 80 },
        { label: "排序", prop: "sort", minWidth: 90 },
        { label: "分类名称", prop: "name", minWidth: 110 },
        {
          label: "模板数量",
          prop: "tmpls_count",
          sortable: true,
          minWidth: 100,
        },
        // { label: "显示", prop: "is_show", width: 90 },
      ],
      //筛选对象
      filterForm: {
        page_size: 15, //每页多少条数据
      },
      pageData: {},
      tbody: [],
      selectArr: [],
      templateNumArr: [], // 选中数据模板数量数组，用于判断分类下是否有模板数据
      categoryList: null,
      // 新增编辑弹窗数据
      CategoryData: {
        id: 0,
        name: "",
      },
      // 转移弹窗数据
      TransferData: {
        name: "",
        del_category_id: "",
        merge_category_id: "",
      },
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      openAddDialog: false,
      openTransferDialog: false,
    };
  },
  methods: {
    // 获取分类列表
    getTmplCategory(pageData) {
      this.loading = true;
      tmplCategory(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
        this.templateNumArr = [];
      } else {
        this.selectArr = [];
        this.templateNumArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
          this.templateNumArr.push(item.tmpls_count);
        });
      }
    },
    // 新增分类名称
    handleAddCategory() {
      this.CategoryData.id = 0;
      this.CategoryData.name = "";
      this.openAddDialog = true;
    },
    //数据批量删除
    handleDelCategory() {
      if (this.selectArr.length) {
        if (this.templateNumArr.findIndex((el) => el > 0) !== -1) {
          this.$msgbox
            .confirm(
              "部分分类下还有信息内容，建议先进行内容转移，再删除",
              "提示",
              {
                type: "info",
                confirmButtonText: "仍要删除",
                cancelButtonText: "取消",
              }
            )
            .then((res) => {
              this.handleDelete({ ids: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确认要将选中的分类删除吗？删除后无法恢复。", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ ids: this.selectArr });
            })
            .catch((err) => {});
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (row.tmpls_count > 0) {
        this.$msgbox
          .confirm("该分类下还有信息内容，建议先进行内容转移，再删除", "提示", {
            type: "info",
            confirmButtonText: "仍要删除",
            cancelButtonText: "取消",
          })
          .then((res) => {
            this.handleDelete({ ids: row.id });
          })
          .catch((err) => {});
      } else {
        this.$msgbox
          .confirm("确认要将该分类删除吗？删除后无法恢复。", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ ids: row.id });
          })
          .catch((err) => {});
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      Delete(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //点击转移
    transferRow(row) {
      this.TransferData.name = row.name;
      this.TransferData.del_category_id = row.id;
      this.openTransferDialog = true;
    },
    // 点击编辑
    editRow(row) {
      this.CategoryData.id = row.id;
      this.CategoryData.name = row.name;
      this.openAddDialog = true;
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getTmplCategory(this.filterForm);
    },
    // 刷新列表
    reflashList() {
      this.getTmplCategory({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {};
      data.id = e.id;
      data[key] = e[key];
      this.loading = true;
      // if (key === "is_show") {
      //   // 更新显示状态
      //   updateShow(data)
      //     .then((res) => {
      //       this.$message.success(res.msg);
      //       this.reflashList();
      //     })
      //     .catch(() => {
      //       this.reflashList();
      //     });
      // } else {
      // 更新排序状态
      updateSort(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch(() => {
          this.reflashList();
        });
      // }
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getTmplCategory(pageData);
    },
  },
  created() {
    this.getTmplCategory(this.filterForm); //获取列表数据
  },
  components: { Pagination, AddClassify, TransferClassify, PopoverInputEdit },
};
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }

    .tool-tip {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .status-dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }

    .green {
      background-color: rgba(19, 206, 102, 1);
    }

    .yellow {
      background-color: rgba(255, 203, 44, 1);
    }
  }
}
</style>